<template>
  <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" class="bt">
    <!--  New Logic Display the button on both view and execute  ***START**** -->
    <div v-if="buttonStatus">
      <span style="display:none;">{{
        parseFloat(getValue).toFixed(2)
      }} {{ isVisible }}</span>
      <el-button type="success" round @click="proceed" :disabled="!data.validations.payAmount || readonly()">
        {{ data.label }}
        {{ parseFloat(data.validations.payAmount).toFixed(2) }}
        {{ data.validations.currency }} </el-button>
      <span @click="paymentHistory"> <i class="el-icon-info"></i></span>
    </div>

    <div v-else>
      <span style="display:none;">{{
        parseFloat(getValue).toFixed(2)
      }} {{ isVisible }}</span>
      <el-row>
        <el-col :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24">
          <span>
            <label for="text" v-if="hasLabel" :style="getLabelStyles">
              {{ data.label }}
            </label>
          </span>
          <span v-if="data.validations.required && hasLabel" style="color: red; font-weight: bold">
            *
          </span>
          <span v-if="data.description" style="color: #c0c4cc; font-weight: bold">
            <el-tooltip :content="data.description" placement="top" effect="dark">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </span>
        </el-col>
      </el-row>

      <span v-if="isView">
        {{ form[data.key] }}
        <span style="display: none">
          {{ parseFloat(getValue).toFixed(2) }}
          {{ isVisible }}
        </span>
      </span>
      <span v-else>
        <span v-if="form && data.key && form[data.key]"> {{ form[data.key] }}</span><span v-else> NO ACTION DONE</span>

        <span style="display: none">
          {{ parseFloat(getValue).toFixed(2) }}
          {{ isVisible }}
        </span>
      </span>
      <span @click="paymentHistory"> <i class="el-icon-info"></i></span>
    </div>

    <!--  New Logic Display the button on both view and execute  ***END**** -->
    <dialog-component v-loading="dialogLoader" :title="modalTitle" :visible="historyPopUp" @before-close="closePopUps"
      :containerWidth="'75vw'" :containerMaxHeight="'80vh'" :isShowFooter="false">
      <el-table :data="form[data.key + '_history']">
        <el-table-column property="txn_id" label="Transaction Id"></el-table-column>
        <el-table-column property="paymentDate" label="Transaction Date and Time">
          <template slot-scope="scope">
            {{ $moment.utc(scope.row.paymentDate).local().format('YYYY-MM-DD hh:mm:ss A') }}
          </template>
        </el-table-column>
        <el-table-column property="gateway" label="Gateway"></el-table-column>
        <el-table-column property="paymentType" label="Through">
          <template slot-scope="scope"><span class="badge badge-primary">{{scope.row.paymentType}}</span> </template>
        </el-table-column>
        <el-table-column property="paidAmount" label="Amount">
          <template slot-scope="scope">{{ scope.row.currency }}&nbsp;{{ scope.row.paidAmount }}</template>
        </el-table-column>
      </el-table>
    </dialog-component>
    <dialog-component v-loading="dialogLoader" :title="modalTitle" :visible="partialPaymentVisible"
      @before-close="handleClose" :containerWidth="'50vw'" :containerMaxHeight="'70vh'" :isShowFooter="true">
      <template>
        <el-radio v-model="partialPaymentStatus" :label="1">Pay Full amount</el-radio>
        <el-radio v-model="partialPaymentStatus" :label="0">Pay partial amount</el-radio>
        <br /> <br />
        <el-input-number v-if="!partialPaymentStatus" :precision="2" v-model="data.validations.payAmount" :min="1"
          :max="data.validations.currentTxnAmount" placeholder="Enter other amount " />
      </template>
      <span slot="footer" class="dialog-footer">
        <div>
          <button type="button" class="btn btn-sm btn-outline-primary" @click="payInitiate">
            Proceed to Pay
          </button>
        </div>
      </span>
    </dialog-component>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
import { payButton } from "../../../utils/swirepay/index";
import { bus } from "../../../main";
import Config from "../../../config/app";
import axios from "@/config/axios";
import moment from "moment";
import systemAxios from "@/config/axios";
import Swal from "sweetalert2";
export default {
  name: "templates-formComponentsExecute-SingleLineTextExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "checkIsDisabled",
    "profilePage",
    "entityDataId",
    "entityData",
    "currentEntity",
    "currentStep",
    "isFromEntity",
    "isFromFormBuilder",
    "fieldsData",
    "templateData",
    "type",
    "isApplicationUserSide"
  ],
  async mounted() {
    this.loading = true;
    this.loadingText = "Please Wait....... ";
    this.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.form && this.data.key && this.form[this.data.key + '_history']) {
      // console.log("History Fund")
    }
    else {
      this.$set(this.form, this.data.key + '_history', []);
    }
    this.data.validations.totalAmount = 0;
    if (this.data.default_value && !this.form[this.data.key]) {
      this.$set(this.form, this.data.key, this.data.default_value);
    }
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
    }
    this.loading = false;
    if (this.entityDataId) {
      this.getTemplateIdsData();
    }
    if(this.currentEntity && this.currentEntity.company_id)
    {
    await Promise.all([
      this.$store.dispatch("company/fetchCompany", this.currentEntity.company_id),
    ]);
    }
  },
  computed: {
    ...mapGetters("globalVariables", [
      "getGlobalVariableById"
    ]),
    ...mapGetters("paymentGateway", [
      "getPlansData",
      "getActivePaymentSystems",
      "getPaymentGatewayInfo",
      "getUpdateSystemInfo",
      "getIntegratedList",
      "getEntityPaymentStatus"
    ]),
    ...mapGetters("formbuilderData", [
      "getNewWorkflowData",
      "getWorkflowTemplateDataAddStatus",
      "getNewFormbuilderData",
      "getUserFormbuilderData",
      "getFormbuilderDataUpdateStatus",
    ]),
    ...mapGetters("templatesData", [
      "getRequiredTemplatesData",
      "getDuplicateDataCheck",
      "getUploadAndUpdateDocument",
      "getTemplatesDataUpdateStatus",
      "getUserTemplateDataUpdateErrors",
      "getNewEntityCreateError",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getTemplatesData"
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
    ]),
    isVisible() {
      if (this.data.validations.totalAmount - this.getTransactionTotal > 0) {
        this.updateButtonStatus(true)
        return true;
      }
      else {
        this.updateButtonStatus(false)
        return false;
      }
    },
    getStyle() {
      return this.data.styles &&
        this.data.styles.label_color &&
        !this.fromRepeatable
        ? `height:${this.data.height - 30}px; font-weight: 400; width: ${this.data.width
        }px;`
        : "height: 70px";
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getValue() {
      if (this.data.validations && this.data.validations.entityVariable && this.data.validations.entityVariable.key) {
        let fields = [{ ...this.data.validations.entityVariable }];
        let data = {};
        if (Object.keys(this.form).length) {
          Object.keys(this.form).forEach((key) => {
            if (key.indexOf("#") > -1) {
              data[key.split("#")[1]] = this.form[key];
            } else {
              if (fields[0].input_type === "CURRENCY") {
                data[key] = this.form[key];
              } else if (fields && fields[0]) {
                data[key] = this.form[key];
              } else {
                data[key] = parseFloat(this.form[key]).toFixed(
                  this.data.decimals
                );
              }
            }
          });
        }
        fields.forEach((fl) => {
          if (fl.template_id) {
            data[fl.key] = this.getTemplateValue(fl.template_id, fl.key);
          }
          else if (fl.input_type === "CURRENCY") {
            data[fl.key] = this.form[fl.key] ? this.form[fl.key] : 0;
            data[fl.key + '_currency'] = this.form[fl.key + '_currency'] ? this.form[fl.key + '_currency'] : 'USD';
          }
          else {
            data[fl.key] = this.form[fl.key] ? this.form[fl.key] : '';
          }
        });
        let pendingAmount = 0;
        if (this.getTransactionTotal < data[this.data.validations.entityVariable.key]) {
          pendingAmount = data[this.data.validations.entityVariable.key] - this.getTransactionTotal;
        }
        else if (data[this.data.validations.entityVariable.key] < this.getTransactionTotal) {
          //pendingAmount=this.getTransactionTotal - data[this.data.validations.entityVariable.key];
          // pendingAmount= data[this.data.validations.entityVariable.key]-this.getTransactionTotal;
        }
        else {
          // pendingAmount=this.getTransactionTotal;
        }
        this.updateAmount(data[this.data.validations.entityVariable.key], pendingAmount, this.data.validations.currency)
        return data[this.data.validations.entityVariable.key];
      }
      else { return 0; }
    },
    getTransactionTotal() {
      if (this.data.validations && this.data.validations.entityVariable && this.data.validations.entityVariable.key && this.data.validations.transactionsCal) {
        let transactions = this.form && this.data.key && this.form[this.data.key + "_history"];
        let sum = 0;
        transactions && transactions.map(txn => {
          sum = sum + txn.paidAmount
        });
        return sum;
      }
      else {
        return 0;
      }
    },
    getEntityId() {
      return this.currentEntity?._id || this.$route.params.entity_id;
    },

  },
  data() {
    return {
      localTimezone: '',
      paymentDialog: false,
      paymentLink: '',
      dialogVisible: false,
      isList: false,
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      entityRepeatableTemplateData: {},
      hasRepeatableTemplate: false,
      entitiesData: [],
      loadingText: "Loading ....!",
      buttonStatus: false,
      partialPaymentVisible: false,
      partialPaymentStatus: 1,
      tempStorage: {},
      selectedInvoice: null,

      dialogLoader: false,
      modalTitle: '',
      historyPopUp: false,
    };
  },
  created() {
    bus.$on("initPayButton", (data) => {
      if (data) {
        this.paymentActionAfterEntityDataCreated(data.apiKey, data.paymentSystem, data.dataId, data.location, {}, data.entity_id, data.template_id);
        bus.$off("initPayButton")
      }
    });

    bus.$on("initTemplatePayButton", (data) => {
      if (data) {
        this.paymentActionAfterEntityDataCreated(data.apiKey, data.paymentSystem, '', data.location, {}, '', data.template_id, true, data.formBuilderDataId, data.formbuilder_details_id);
        bus.$off("initTemplatePayButton")
      }
    });
  },

  methods: {
    handleClose() {
      this.$confirm("Are you sure to cancel the transaction? ", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async () => {
        this.loading = true;
        this.partialPaymentVisible = false;
        this.data.validations.payAmount = this.data.validations.currentTxnAmount;
        this.partialPaymentVisible = false;
        this.dialogLoader = false;
        this.historyPopUp = false;
        this.modalTitle = '';
        this.loading = false;
      });
    },
    onLoad(frame) {
      console.log(frame)
    },
    updateButtonStatus(status = false) {
      this.buttonStatus = status;
    },
    updateAmount(fullAmount, balanceAmount, currency) {
      this.data.validations.totalAmount = fullAmount;
      this.data.validations.payAmount = balanceAmount ? balanceAmount : 0;
      this.data.validations.currency = currency ? currency : this.data.validations.currency;
      if (balanceAmount) { this.updateButtonStatus(true) }
    },
    getTemplateValue(templateId, key) {
      let value = 0;
      if (this.getTemplatesData && this.getTemplatesData.length) {
        this.getTemplatesData.forEach(item => {
          if (item.template_id == templateId) {
            value =
              item.template_data && item.template_data[key]
                ? item.template_data[key]
                : "";
          }
        });
        return value ? value : "";
      }
    },
    async getTemplateIdsData() {
      let templateIds = [];

      let fields = [{ ...this.data.validations.entityVariable }];
      fields.forEach(element => {
        if (element.template_id) {
          if (templateIds.indexOf(element.template_id) < 0) {
            templateIds.push(element.template_id);
          }
        }
      });
      let params = {
        template_ids: templateIds,
        entity_data_id: this.entityDataId,
        status: "ACTIVE"
      };
      await this.$store.dispatch("templatesData/fetchUserTemplateData", params);
    },

    async proceed() {
      //Proceed To check The Data
      this.loading = true;
      this.loadingText = "Initializing the process....";
      await this.$store.dispatch("paymentGateway/fetchInitSystemsData", "Id");
      if (this.getIntegratedList && this.getIntegratedList.cred_details) {
        let paymentSystem = this.getIntegratedList.cred_details.filter(
          (e) => e._id == this.data.validations.paymentSystems[0]
        );
        let bytes = await this.$CryptoJS.AES.decrypt(paymentSystem[0].crypto, paymentSystem[0].ivKey);
        let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
        this.$set(this.form, this.data.key, "INITIATED");
        if (this.form && this.data.key && this.form[this.data.key + '_history'] && this.form[this.data.key + '_history'].length) {
          if (this.form[this.data.key + '_history'].length) {
            this.$set(this.form, this.data.key, "PARTIALLY PAID");
          }
          else {
            this.$set(this.form, this.data.key, "INITIATED");
          }
          //do nothing
        }
        else {
          // this.form[this.data.key + '_history']=[];
          this.$set(this.form, this.data.key + '_history', []);
          // this.form[this.data.key + '_history_total']=0;
          this.$set(this.form, this.data.key, "INITIATED");
        }
        if (this.entityDataId && this.entityDataId.length) {
          this.loading = true;
          this.loadingText = "Data is saving.... Please wait....."
          let customer = {};
          if (this.currentEntity && this.currentEntity.entity_type === "INDIVIDUAL") {
            // fetch default entity Info
            let getContactTemplate = this.currentEntity && this.currentEntity.templates && this.currentEntity.templates[0];
            let getContactTemplateData = this.getTemplatesData.filter(tempData => {
              return tempData.template_id === getContactTemplate.template_id;
            })
            if (getContactTemplateData && getContactTemplateData.length) {
              customer.email = getContactTemplateData[0].template_data.email;
              customer.name = getContactTemplateData[0].template_data.name;
              // if (getContactTemplateData[0] && getContactTemplateData[0].template_data.phone_number) {
              //   customer.phoneNumber = getContactTemplateData[0].template_data.phone_number
              // }
            }
          }
          let entityId = null;
          let templateId = null;
          if (this.type === "APP_USER") {

            bus.$emit('pay-button-action-app-user', 'UPDATE');
            entityId = this.currentEntity._id;
            templateId = this.currentStep.template_id;
          }
          if (this.type === "FORM_BUILDER_ENTITY_TEMPLATES") {
            entityId = this.currentEntity._id;
            templateId = this.currentStep.template_id;
            bus.$emit('pay-button-action-form-builder-entity', { type: 'FORM_BUILDER_ENTITY_UPDATE', action: false, form: this.form })
          }
          if (this.type === "FORM_BUILDER_TEMPLATE") {
            entityId = this.currentEntity._id;
            templateId = this.currentStep.template_id;
            bus.$emit('pay-button-action-form-builder-template', { type: 'FORM_BUILDER_TEMPLATE_UPDATE', action: false, form: this.form })
          }
          else {
            entityId = this.currentEntity._id;
            templateId = this.currentStep.template_id;
            bus.$emit('pay-button-action', { action: 'UPDATE', entityId, templateId, entityDataId: this.entityDataId });

          }
          this.paymentActionAfterEntityDataCreated(decryptedData.apiKey, { ...paymentSystem[0], pg_account_id: this.getIntegratedList._id }, this.entityDataId, window.location.href, customer, entityId, templateId)
        }
        else {
          this.loading = true;
          this.loadingText = "Data is saving......"
          let location = '';
          if (this.type === "FORM_BUILDER_TEMPLATE") {
            //form builder Data
            location = window.location.href;
            bus.$emit('pay-button-action-form-builder-template', { type: 'FORM_BUILDER_TEMPLATE_UPDATE', action: true, form: this.form, apiKey: decryptedData.apiKey, paymentSystem: { ...paymentSystem[0], pg_account_id: this.getIntegratedList._id }, location });
          }
          else if (this.type === "FORM_BUILDER_ENTITY_TEMPLATES") {
            location = window.location.href;
            bus.$emit('pay-button-action-form-builder-entity', { type: 'FORM_BUILDER_ENTITY_UPDATE', action: true, form: this.form, apiKey: decryptedData.apiKey, paymentSystem: { ...paymentSystem[0], pg_account_id: this.getIntegratedList._id }, location })
          }
          else {
            await this.saveEntityData();
            if (this.getNewEntityData?._id) {
              if (this.isApplicationUserSide) {
                let menuId = this.$route.params.menuId || this.$route.query.menu_id;
                let filter = this.$route.query.filter || '';
                location = `/ap/entity-execute/${this.currentEntity._id}?key=${Math.floor(Math.random() * 100000)}&dataId=${this.getNewEntityData._id}&filter=${filter}&menu_id=${menuId}&layout=STANDARD&page=1&pageSize=10&type=AUfYWb7SC6U%3D`
              }
              // FormbuilderGuestAuth FormbuilderGuestUserAuth FormbuilderGuestUserPreviewAuth GuestFormbuilderView GuestFormbuilderApprovalExecute
              else if (this.$route.name == "GuestFormbuilderExecute" || this.$route.name == "GuestFormbuilderExecuteStep") {
                location = Config.APP_URL + this.$route.fullPath;
              }
              else {
                location = `${Config.APP_URL}/entity/edit/${this.currentEntity._id}?key=${Math.floor(Math.random() * 100000)}&dataId=${this.getNewEntityData._id}&filter=&layout=STANDARD`;
              }
              this.paymentActionAfterEntityDataCreated(decryptedData.apiKey, { ...paymentSystem[0], pg_account_id: this.getIntegratedList._id }, this.getNewEntityData._id, location, {}, this.currentEntity._id, this.currentStep.template_id)
            }
          }
        }
        this.loading = false;
        this.loadingText = "Loading ......"
      }

      this.loading = false;

    },
    async payInitiate() {
      if (this.data.validations.payAmount) {
        this.data.validations.payAmount = parseFloat(this.data.validations.payAmount);
        // this.paymentAction(...this.tempStorage);
        this.paymentAction(this.tempStorage.apiKey, this.tempStorage.sessionData, this.tempStorage.entityDataId, this.tempStorage.location, this.tempStorage.customer, this.tempStorage.entityId, this.tempStorage.templateId, this.tempStorage.type, this.tempStorage.formBuilderDataId, this.tempStorage.formBuilderId);
      }
      else {
        alert("Please enter payable amount");
      }
    },
    async paymentActionAfterEntityDataCreated(apiKey, sessionData, entityDataId, location, customer, entityId, templateId, type = false, formBuilderDataId = '', formBuilderId = '') {
      this.tempStorage = {
        apiKey, sessionData, entityDataId, location, customer, entityId, templateId, type: type, formBuilderDataId: formBuilderDataId, formBuilderId: formBuilderId,
        amount: this.data.validations.payAmount,
        currency: this.data.validations.currency
      }

      if (this.data.validations.partialPayment) {
        this.partialPaymentVisible = true;
        this.modalTitle = `Payment initiation  of ${this.data.validations.currency} ${this.tempStorage.amount.toFixed(2)}`
        this.data.validations.currentTxnAmount = this.data.validations.payAmount;
      }
      else {
        this.partialPaymentVisible = false;
        this.modalTitle = '';
        this.paymentAction(this.tempStorage.apiKey, this.tempStorage.sessionData, this.tempStorage.entityDataId, this.tempStorage.location, this.tempStorage.customer, this.tempStorage.entityId, this.tempStorage.templateId, this.tempStorage.type, this.tempStorage.formBuilderDataId, this.tempStorage.formBuilderId);
      }
    },
    async paymentAction(apiKey, sessionData, entityDataId, location, customer, entityId, templateId, type = false, formBuilderDataId = '', formBuilderId = '') {
      //Redirection Url 
      if (this.data && this.data.validations && this.data.validations.redirectUrl) {
        this.data.validations.defaultRedirectUrl = '/entity-payment-status-update?SessionData=' + sessionData._id + '&location=' + window.btoa(this.data.validations.redirectUrl)+'&temp='+templateId;
      }
      else {
        this.data.validations.defaultRedirectUrl = '/entity-payment-status-update?SessionData=' + sessionData._id + '&location=' + window.btoa(location)+'&temp='+templateId;
      }
      //add customer to payment link
      /* Modes validation As of now we have configured Card and ACH for USA ("ACH", "CREDIT CARD") **/
      let payModes = [];
      if (this.data && this.data.validations && this.data.validations.payModes && this.data.validations.payModes.length) {
        this.data.validations.payModes.forEach((mode) => {
          if (mode == 'CREDIT CARD') { payModes.push("CARD") }
          else if (mode == 'ACH') { payModes.push("ACH_LEGACY") }
        })
      }
      else {
        payModes.push("CARD")
      }

      /* payment link generation Description for further our system usage **/
      let description = {
        entityDataId: entityDataId,
        location: this.data && this.data.validations && this.data.validations.redirectUrl ? this.data.validations.redirectUrl : location,
        // entityId: this.currentEntity && this.currentEntity._id,
        entityId: entityId,
        // templateId: this.currentStep &&this.currentStep.template_id,
        templateId: templateId,
        key: this.data.key,
        amount: this.data.validations.totalAmount ? this.data.validations.totalAmount : this.getValue,
        paymentInit: this.data.validations.payAmount,
        currency: this.data.validations.currency,
        type: type ? 'FORM_BUILDER' : '',
        formBuilderDataId: formBuilderDataId,
        formBuilderId: formBuilderId
      };
      let bytes = await this.$CryptoJS.AES.decrypt(sessionData.crypto, sessionData.ivKey);
      let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
      if (sessionData.pgType == "SWIREPAY") {        
        let swirepayInfo = await payButton(apiKey, parseInt((this.data.validations.payAmount * 100).toFixed(2)), 0, this.data.validations.currency, this.data.description, this.data.validations && this.data.validations.defaultRedirectUrl ? Config.APP_URL + this.data.validations.defaultRedirectUrl : location, 'test', payModes, description, customer);

        if (swirepayInfo && swirepayInfo.responseCode == 200 || swirepayInfo && swirepayInfo.status == "SUCCESS") {
          // this.paymentDialog=true;
          // this.paymentLink= swirepayInfo.entity.link

          /* redirecting to Gateway **/
          window.location.href = swirepayInfo.entity.link;
        }
        else {
          console.log("swirepayInfo Error", swirepayInfo)

          this.$notify.error({
            title: "Error",
            message: swirepayInfo.message ? swirepayInfo.message : "Error at payment system."
          });

          this.paymentDialog = false;
          this.paymentLink = '';
        }
      }
      else if (sessionData.pgType == "RAZORPAY") {

        const res = await this.loadScript(
          'https://checkout.razorpay.com/v1/checkout.js'
        );
        if (!res) {
          alert('Razorpay SDK failed to load. Are you online?');
          return;
        }
        let data = {
          amount: this.data.validations.payAmount * 100,
          fee: 0,
          currency: this.data.validations.currency,
          description: this.data.description,
          redirectUrl: this.data.validations && this.data.validations.defaultRedirectUrl ? Config.APP_URL + this.data.validations.defaultRedirectUrl : location,
          modes: payModes,
          optionItems: description,
          customer: customer
        }
        const result = await axios.post(`${Config.PAYMENT_GATEWAY_URL}/razorpay/order?pgAccountId=${sessionData.pg_account_id}&txnAcId=${sessionData._id}`, data);

        if (!result) {
          alert('Server error. Are you online?');
          return;
        }

        const { amount, id: order_id, currency } = result.data;
        const options = {
          key: decryptedData.publicKey, // Enter the Key ID generated from the Dashboard
          amount: amount.toString(),
          currency: currency,
          name: this.getCompanyDetails && this.getCompanyDetails.legal_name?this.getCompanyDetails.legal_name:"ESIGNS",
          description: data.description,
          image: this.getCompanyDetails && this.getCompanyDetails.logo?`${Config.S3_BUCKET_URL}/${this.getCompanyDetails.logo}`:"https://esigns.io/images/logo-dark.png",
          order_id: order_id,
          handler: async function (response) {
            let successTimer;
            Swal.fire({
              title: "Validating.....",
              html: `Verifying the payment.... please wait while `,
              timer: 100000,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              didOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                clearInterval(successTimer);
                // this.logout();
              },
            });

            const successData = await axios.get(`${Config.PAYMENT_GATEWAY_URL}/razorpay/verify/${response.razorpay_payment_id}?pgAccountId=${sessionData.pg_account_id}&txnAcId=${sessionData._id}`);
            if (successData.status === 200) {
              Swal.close()
              let timerInterval;
              Swal.fire({
                title: "Processing...",
                html: `We're verifying the status of your payment.It may take few minutes to receive payment status and update your  status.Please do not close this window, as it will impact your payment.`,
                timer: 100000,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                  Swal.showLoading();
                },
                willClose: () => {
                  clearInterval(timerInterval);
                  // this.logout();
                },
              });
              let data = successData.data
              let params = {
                ...data.notes, status: "SUCCESS",
                txnDetails: {
                  txn_id: data.id,
                  gateway: "RAZORPAY",
                  paymentId: data.order_id,
                  name: '',
                  email: data.email,
                  phoneNumber: data.contact,
                  paymentSession: response.razorpay_signature,
                  amount: data.amount || 0,
                  amountRefunded: data.amount_refunded || 0,
                  description: "",
                  paymentDate: moment.utc(),
                  refundDate: '',
                  status: data.status == "captured" ? 'Paid' : 'Not Paid',
                  paymentType: data.method || "CARD",
                  method: data.method || '',
                  currency: data.currency || 'USD'
                }
              }
              let apiResponse = await systemAxios.post(`/updateRulesOfPayBtn`, params);
              if (apiResponse.data) {
                setTimeout(() => {
                  window.location.href = data.notes.location;
                  Swal.close();
                }, 3000);
              }

            }
            else {
              alert(successData.status + ' Error');
            }

          },
          prefill: {
            name: customer.name,
            email: customer.email,
            // contact: customer.phoneNumber,
          },
          notes: {
            address: '',
          },
          theme: {
            color: '#61dafb',
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }


    },
    loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },
    async saveEntityData() {
      try {
        this.loading = true;
        this.loadingText = "Saving your data .... Please wait......";
        let form = {};
        let templateId = this.templateData && this.templateData._id ? this.templateData._id : this.currentStep && this.currentStep.template_id ? this.currentStep.template_id : ""
        if (templateId) {
          if (this.hasRepeatableTemplate) {
            let key = "";
            if (
              this.entityRepeatableTemplateData &&
              this.entityRepeatableTemplateData.templateInfo &&
              this.entityRepeatableTemplateData.templateInfo.name
            ) {
              let name = this.entityRepeatableTemplateData.templateInfo.name;
              key = name
                .toLowerCase()
                .split(" ")
                .join("_");
            } else {
              key = "templateData";
            }
            form[key] = this.entitiesData.map(e => {
              if (e.isNewData) {
                delete e.isNewData;
              }
              return e;
            });
          } else {
            // form = this.form;
            let incrementFields = this.fieldsData.filter(
              e => e.input_type == "AUTO_INCREMENT_NUMBER"
            );

            if (incrementFields && incrementFields.length) {
              await incrementFields.map(async field => {
                this.form[`${field.key}_info`] = {
                  number: field.auto_increment_starting_number,
                  prefix: field.auto_increment_prefix
                };
              });
            }

            form = await this.prepareFormData();
            if (
              form &&
              form.first_name &&
              form.last_name &&
              form.first_name.length &&
              form.last_name.length &&
              !form.name
            ) {
              form.name = form.first_name + " " + form.last_name;
            }
          }
          let data = {
            template_id: templateId,
            // template_id:this.templateData._id,
            template_data: form,
            user_id: this.getNewFormbuilderData,
            entity_id: this.getEntityId,
            template_completion_status: false,
            entity_data_id: null
          };
          await this.$store.dispatch(
            "templatesData/createEntityDataByTemplateData",
            data
          );

          if (this.getEntityDataCreateByTemplateStatus) {
            this.loading = false;
            this.$notify.success({
              title: "Success",
              message: "Data saved successfully"
            });

            if (this.getNewEntityData?._id) {
              /* Relation ship Data functions */
              // this.checkAndSetRelationship(this.getNewEntityData?._id);
              // we have to do entity stuff
              // await this.updateEntitiesData();

            }
          } else {
            if (
              this.getNewEntityCreateError &&
              this.getNewEntityCreateError.message
            ) {
              this.$notify.error({
                title: "Error",
                message: this.getNewEntityCreateError.message
              });
            } else {
              this.$notify.error({
                title: "Error",
                message: "Error at saving data"
              });
            }
            this.loading = false;
          }
        }
        else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Failed to find the current step information"
          });
        }
        this.loading = false;

      } catch (err) {
        console.log(err);
      }
    },
    async prepareFormData() {
      for (let [key, value] of Object.entries(this.form)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loading = true;
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.form[key].path = path;
          this.form[key].new = false;
        }
      }
      return this.form;
    },
    async uploadFile(file) {
      var formData = new FormData();
      file.forEach((el) => {
        formData.append("files", el.raw !== undefined ? el.raw : el);
      });
      formData.append("path", "template-data-documents");
      let filesData = await axios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(filesData);
    },

    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "SINGLE_LINE_TEXT") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }

        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    paymentHistory() {
      this.loading = true;
      this.partialPaymentVisible = false;
      this.dialogLoader = true;
      this.historyPopUp = true;
      this.modalTitle = 'Payment History';
      this.loading = false;
      this.dialogLoader = false;

    },
    closePopUps() {
      this.loading = true;
      this.partialPaymentVisible = false;
      this.dialogLoader = false;
      this.historyPopUp = false;
      this.modalTitle = '';
      this.loading = false;
    }

  },
  watch: {
    "form": {
      handler: function () {
        console.log(this.getValue, this.isVisible);
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.paymentDialog {
  .el-dialog__wrapper {
    .el-dialog__header {
      background: transparent !important;
      //border-bottom: none;
      padding: 0;
      //border-radius: 5px 5px 0 0;
    }
  }
}

.swal2-container {
  z-index: 2048 !important;
}
</style>
<style lang="scss" >
.swal2-container {
  z-index: 2048 !important;
}
</style>
